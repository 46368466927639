











































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    isLengthPublic: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isPublic: false,
      loading: false,
    };
  },
  watch: {
    isLengthPublic: {
      immediate: true,
      handler(isPublic) {
        this.isPublic = isPublic;
      },
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/SetWaitlistPublic', {
        BUSINESS_ID: this.businessId,
        form: {
          is_public: this.isPublic,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
