






















































































import Vue from 'vue';
import {
  Zone, SelectOption,
} from '@/types';

const BASE_ZONES: Zone[] = [
  // 'nl-ams-2',
  // 'us-az-2',
  'nl-ams-3',
  'us-ca-1',
];

const FLEX_ZONES: Zone[] = [
  'at-vie-1',
  'bg-sof-1',
  'ch-gva-1',
  'de-fra-2',
  'nl-ams-3',
  'sg-sgp-1',
  'us-ca-1',
  'us-ny-1',
];

const DEPRECATED_ZONES: Zone[] = [
  'de-fra-1',
  'ie-dub-1',
  'nl-ams-1',
  'us-az-1',
];

const BETA_ZONES: Zone[] = [
  'nl-ams-2',
  'us-az-2',
];

function sort(opts: SelectOption[]): SelectOption[] {
  return opts.sort((a, b) => {
    if (a.text > b.text) return 1;
    if (a.text < b.text) return -1;
    return 0;
  });
}

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    defaultZone: {
      type: String as () => Zone,
      required: true,
    },
    isFlex: Boolean,
  },
  data() {
    return {
      error: null,
      loading: false,
      zone: this.defaultZone,
    };
  },
  computed: {
    BASE_ZONE_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      BASE_ZONES.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`zone.${value}`),
        });
      });

      return sort(opts);
    },
    FLEX_ZONE_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      FLEX_ZONES.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`zone.${value}`),
        });
      });

      return sort(opts);
    },
    DEPRECATED_ZONE_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      DEPRECATED_ZONES.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`zone.${value}`),
        });
      });

      return sort(opts);
    },
    DEPRECATED_ZONES(): Zone[] {
      return DEPRECATED_ZONES;
    },
    BETA_ZONE_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      BETA_ZONES.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`zone.${value}`),
        });
      });

      return sort(opts);
    },
    BETA_ZONES(): Zone[] {
      return BETA_ZONES;
    },
  },
  watch: {
    defaultZone: {
      immediate: true,
      handler(to) {
        this.zone = to;
      },
    },
  },
  methods: {
    async save() {
      this.loading = true;
      await this.$store.dispatch('business/ChangeDefaultZone', {
        BUSINESS_ID: this.businessId,
        form: {
          zone: this.zone,
        },
      }).catch((e) => { this.error = e; });
      this.loading = false;
    },
  },
});
