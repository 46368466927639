











































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    userLimit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      allow: false,
      error: null,
      loading: false,
      timeout: null as number | null | void,
      success: false,
      validation: null as string | null,
    };
  },
  computed: {
    isMultipleRequestsPerUserAllowed(): boolean {
      return this.userLimit > 1;
    },
  },
  watch: {
    userLimit: {
      immediate: true,
      handler(to) {
        if (to > 1) this.allow = true;
        else this.allow = false;
      },
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/ChangeCommissionRequestSettings', {
        BUSINESS_ID: this.businessId,
        form: {
          multiple_per_user: !this.isMultipleRequestsPerUserAllowed,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
