











































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    isAutomatic: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      automatic: false,
      loading: false,
    };
  },
  watch: {
    isAutomatic: {
      immediate: true,
      handler(automatic) {
        this.automatic = automatic;
      },
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/SetWaitlistReceiving', {
        BUSINESS_ID: this.businessId,
        form: {
          automatic: this.automatic,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
