





























































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import DefaultZoneForm from '@/components/dashboard/DefaultZoneForm.vue';
import EnableMultipleRequestsPerUserForm from '@/components/dashboard/EnableMultipleRequestsPerUserForm.vue';
import SlotsForm from '@/components/dashboard/SlotsForm.vue';
import WaitlistLengthPublicForm from '@/components/dashboard/WaitlistLengthPublicForm.vue';
import WaitlistReceivingForm from '@/components/dashboard/WaitlistReceivingForm.vue';
import WaitlistSlotsForm from '@/components/dashboard/WaitlistSlotsForm.vue';
import MakerSearchVisibilityForm from '@/components/dashboard/MakerSearchVisibilityForm.vue';

export default Vue.extend({
  components: {
    DefaultZoneForm,
    EnableMultipleRequestsPerUserForm,
    MakerSearchVisibilityForm,
    SlotsForm,
    WaitlistLengthPublicForm,
    WaitlistReceivingForm,
    WaitlistSlotsForm,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
});
