



















































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    isSearchVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      allow: false,
      error: null,
      loading: false,
      timeout: null as number | null | void,
      success: false,
      validation: null as string | null,
    };
  },
  watch: {
    isSearchVisible: {
      immediate: true,
      handler(to) {
        this.allow = to;
      },
    },
  },
  methods: {
    async update() {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/ChangeSearchVisibility', {
        BUSINESS_ID: this.businessId,
        form: {
          is_search_visible: this.allow,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
