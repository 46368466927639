
















































import Vue from 'vue';

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    slotsCount: {
      type: Number,
      required: true,
    },
    slotsAvailable: {
      type: Number,
      required: true,
    },
    slotsMax: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
      slots: this.slotsCount,
      timeout: null as number | null | void,
      validation: null as string | null,
    };
  },
  watch: {
    slotsCount: {
      immediate: true,
      handler(to) {
        this.slots = to;
      },
    },
    slots: {
      immediate: false,
      handler() {
        if (this.timeout) this.timeout = void window.clearTimeout(this.timeout);
        if (this.slots !== this.slotsCount) this.timeout = window.setTimeout(this.save, 1250);
      },
    },
  },
  methods: {
    async save() {
      this.loading = true;
      this.error = null;

      await this.$store.dispatch('business/SetSlots', {
        BUSINESS_ID: this.businessId,
        form: {
          slots: this.slots,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
    validate(v: any) {
      this.validation = v.slots;
    },
  },
});
